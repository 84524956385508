@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Merriweather+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bree+Serif&family=Merriweather+Sans:wght@300&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Bebas Neue", sans-serif;
}

html,
#root,
.App {
  scroll-behavior: smooth;
  height: 100%;
  min-height: "100vh";
  background-color: #2a2f36;
}

body {
  margin: 0px;
  padding: 0px;
}

